import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import { loadUser } from '../../../actions/auth';
import { Spinner, Form, Alert } from 'react-bootstrap';
import { AuthenticatedLink } from '../../Shared/ReportAction/AuthenticatedLink';
import { useTranslation } from 'react-i18next';

function SubmitQuestionnaire(props) {
  const { t } = useTranslation();
  let { user, loadUser } = props;
  const navigate = useNavigate();
  const storedReportId = JSON.parse(localStorage.getItem('reportRequestId'));
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [questionsInfo, setQuestionsInfo] = useState([]);
  let [declaration, setDeclaration] = useState(false);
  let [investeeInfoWarning, setInvesteeInfoWarning] = useState(false);

  const displayDataSummary = () => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/export_answer_csv/${storedReportId}?human_friendly=1`;

    return (
      <AuthenticatedLink url={URL} filename='datasummary.csv'>
        <div className='data-summary'>{t('shared.summary')}</div>
      </AuthenticatedLink>
    );
  };

  const fetchQuestionsInfo = async () => {
    const response = await API.get(`api/get_questions_info/${storedReportId}`, { headers });
    let dataArray = Object.keys(response.data).map(function (obj) {
      return response.data[obj];
    });
    setQuestionsInfo(dataArray);
  };

  useEffect(() => {
    fetchQuestionsInfo();
  }, []);

  const handleCheckbox = () => {
    setDeclaration(!declaration);
  };

  const submitQuestionnaire = async () => {
    const isInvestee = localStorage.getItem('userType') === 'Investee';

    if (isInvestee) {
      const storedInvestee = JSON.parse(localStorage.getItem('investee'))?.investee;
      const isInvesteeInfoValid = checkInvesteeInfo(storedInvestee);

      if (!isInvesteeInfoValid) {
        setInvesteeInfoWarning(true);
        return;
      }
    }

    const url = `api/submit_questionnaire/${storedReportId}/`;
    await API.post(url, {}, { headers })
      .then((response) => {
        console.log(response.data);
        window.location.href = '/';
      })
      .catch(function () {
        console.log('Error while adding the agreement page');
      });
  };

  const checkInvesteeInfo = ({
    company_size,
    naics_category_1,
    naics_category_2,
    currency,
    annual_revenue,
    ebitda,
    net_income,
  }) => {
    return company_size && naics_category_1 && naics_category_2 && currency && annual_revenue && ebitda && net_income
      ? true
      : false;
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar reportRequestId={storedReportId} questionsInfo={questionsInfo} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top justify-content-center'>
                <div className='col-8'>
                  <h2 className='padding-bottom text-center'>{t('questionnaire.endQuestionnaire')}</h2>
                  <p>{t('questionnaire.endText1')}</p>
                  <p>
                    {t('questionnaire.endText2')}
                    {displayDataSummary()}{' '}
                  </p>
                  <Alert variant='info'>
                    <Form.Group className='checkbox'>
                      <Form.Check
                        label={t('questionnaire.alert_investee1')}
                        type='checkbox'
                        value={declaration}
                        onChange={handleCheckbox}
                        checked={declaration}
                      />
                    </Form.Group>
                  </Alert>
                  {investeeInfoWarning ? (
                    <div className='alert alert-warning'>
                      {t('questionnaire.alert_investee2')}
                      <button className='ml-1' onClick={() => navigate('/investee/company-name')}>
                        <span>{t('questionnaire.click')}</span>
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                  <button
                    className='primary-button mt-2'
                    onClick={submitQuestionnaire}
                    disabled={declaration === false}
                  >
                    {t('questionnaire.Submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(SubmitQuestionnaire);
